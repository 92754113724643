import _ from 'lodash';

export function humanizeWatts(watts, { round = 2 } = {}) {
    if (watts === null) {
        return '';
    }

    let value = watts;

    let scale;
    for (scale of ['W', 'kW', 'MW', 'GW', 'TW']) {
        if (value < 1000) {
            break;
        }

        value /= 1000;
    }

    value = _.round(value, round);
    return `${value} ${scale}`;
}

export function stringifyNumber(number) {
    return number.toLocaleString('en-US');
}
