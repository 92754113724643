import {
    partialRight,
    sortedIndex,
    sortedIndexBy,
    sortedLastIndex,
    sortedLastIndexBy,
} from 'lodash';

const _identity = (x) => x;

export function flMap(initializer = _identity) {

    if (this !== undefined && !this.__esModule) {
        throw Error('dont use new to initialize a flMap');
    }

    const map = new Map();

    map.get = (key) => {
        let val = Map.prototype.get.call(map, key);

        if (val === undefined) {
            val = initializer(key);
            map.set(key, val);
        }

        return val;
    };

    map.pop = (key) => {
        const val = map.get(key);
        map.delete(key);
        return val;
    };

    return map;
}


export function incrementMap(map, key, val) {
    let oldVal = (map.get(key) || 0);
    oldVal += val;
    map.set(key, oldVal);

    return oldVal;
}


export class OrderedMap {
    constructor(sortBy = undefined) {
        this.dict = {};
        this.keys = [];

        if (sortBy) {
            this.sortedIndex = partialRight(sortedIndexBy, sortBy);
            this.sortedLastIndex = partialRight(sortedLastIndexBy, sortBy);
        } else {
            this.sortedIndex = sortedIndex;
            this.sortedLastIndex = sortedLastIndex;
        }

        this._boundGet = this.get.bind(this);
        this._boundGetEntry = this.getEntry.bind(this);
    }

    get size() {
        return this.keys.length;
    }

    get length() {
        return 0; // match builtin Map API
    }

    set(key, val) {
        if (this.dict[key] !== undefined) {
            this.dict[key] = val;
            return;
        }

        const index = this.sortedIndex(this.keys, key);
        this.keys.splice(index, 0, key);
        this.dict[key] = val;
    }

    get(key) {
        return this.dict[key];
    }

    getEntry(key) {
        return [key, this.dict[key]];
    }

    /**
     * return the value stored with a key that is >= the supplied key or undefined
     */
    getUpper(key) {
        const index = this.sortedLastIndex(this.keys, key);
        return this.dict[this.keys[index]];
    }

    /**
     * return the value stored with a key that is < the supplied key or undefined if there is none
     */
    getLower(key) {
        const index = this.sortedIndex(this.keys, key);
        return this.dict[this.keys[index - 1]];
    }

    keys() {
        return this.keys;
    }

    values() {
        return this.keys.map(this._boundGet);
    }

    entries() {
        return this.keys.map(this._boundGetEntry);
    }

    [Symbol.iterator]() {
        return this.entries().values();
    }
}

export function toObject(collection, keyFunc) {
    const obj = {};
    for (const item of collection) {
        obj[keyFunc(item)] = item;
    }

    return obj;
}
