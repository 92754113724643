/* eslint prefer-rest-params: 0 */

import { throttle, isFunction } from 'lodash';
import Logger from 'js-logger';
import platform from 'platform';
const logger = Logger.get('statsd');

const pending = [];
const THROTTLE_DURATION = 1000 * 60; // flush every 60 seconds


// if defined, will POST results here, if not, will log
let _endpoint = null;

export function configure(configEndpoint) {
    if (!configEndpoint) {
        throw Error('No endpoint supplied to configuration');
    }

    _endpoint = configEndpoint;
}


async function submit() {
    if (pending.length === 0) {
        return;
    }

    // Disable Statsd until SSL fixed on cloudfront/lambda instance
    logger.info('Ignoring statsd data');
    pending.length = 0;
    return;
    //////////////////////////////////////////////////////////

    const payload = JSON.stringify({
        metrics: pending,
        tags: [
            `browser:${platform.name} ${platform.version}`,
            `browser_family:${platform.name}`,
        ],
    });

    pending.length = 0;

    try {
        if (!_endpoint) {
            return;
        }

        const response = await fetch(_endpoint,
            {
                body: payload,
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        const jsonResponse = await response.json();
        if (jsonResponse.status !== 'ok') {
            logger.warn('Failed submitting statsd');
        }
    } catch (e) {
        logger.warn('Failed submitting statsd');
    }
}

const submitThrottled = throttle(submit, THROTTLE_DURATION, {
    leading: false,
    trailing: true,
});

function enqueue(operation) {
    pending.push(operation);
    submitThrottled();
}

export function submitBootMetrics(bootMetrics) {
    const now = performance.now();
    timing('boot.pre_js_duration', bootMetrics.jsLoadStartTime - bootMetrics.startTime);
    timing('boot.js_load_duration', bootMetrics.jsRunStartTime - bootMetrics.jsLoadStartTime);
    timing('boot.js_boot_duration', now - bootMetrics.jsRunStartTime);
    timing('boot.total_load_duration', now - bootMetrics.startTime);
}

export function timing(metricName, duration) {
    enqueue({
        event_type: 'timing',
        metric_name: metricName,
        duration,
        timestamp: (new Date()).getTime() / 1000,
    });
}

function instrumentFunction(func, name) {
    return function wrapper() {
        const timer = new Timer(name);
        try {
            return func.apply(this, arguments);
        } finally {
            timer.end();
        }
    };
}


function instrumentMethod(name) {
    return function deco(target, property, descriptor) {
        const originalFunc = descriptor.value;

        function wrapper() {
            const timer = new Timer(name);
            try {
                return originalFunc.apply(this, arguments);
            } finally {
                timer.end();
            }
        }

        descriptor.value = wrapper;
    };
}


function instrumentFunctionAsync(func, name) {
    return async function wrapper() {
        const timer = new Timer(name);
        const rtn = await func.apply(this, arguments);
        timer.end();
        return rtn;
    };
}


function instrumentMethodAsync(name) {
    return function deco(target, property, descriptor) {
        const originalFunc = descriptor.value;

        async function wrapper() {
            const timer = new Timer(name);
            const rtn = await originalFunc.apply(this, arguments);
            timer.end();
            return rtn;
        }

        descriptor.value = wrapper;
    };
}

export function instrumentAsync() {
    // functions are handled differently, since decorators are not supported.
    if (isFunction(arguments[0])) {
        return instrumentFunctionAsync(...arguments);
    } else {
        return instrumentMethodAsync(...arguments);
    }
}

/**
 * Instrument a class or a function.
 * usage:
 * class Foo {
 *     @instrument('bar!')
 *     bar() {
 *     }
 *
 * function bar() { }
 * bar = instrument(bar, 'bar');
 * @returns {Function}
 */
export function instrument() {
    // functions are handled differently, since decorators are not supported.
    if (isFunction(arguments[0])) {
        return instrumentFunction(...arguments);
    } else {
        return instrumentMethod(...arguments);
    }
}

export class Timer {
    constructor(name) {
        this.name = name;
        this.start = performance.now();
        this.finished = false;
    }

    end() {
        this.finished = true;

        // ms
        const duration = performance.now() - this.start;
        timing(this.name, duration);
    }
}


export class Counter {
    constructor(name) {
        this.name = name;
        this.count = 0;
    }

    increment(inc = 1) {
        this.count += inc;
    }

    submit() {
        // note: using the timing API to submit this as a hack to get a count for
        // Google Sunroof
        timing(this.name, this.count);
        this.count = 0;
    }
}
